<template>
  <div class="reception-container">
    <template v-if="pageShow">
      <van-sticky>
        <head-bar type="primary" title="参会信息" @onClickLeft="onClickLeft" />
      </van-sticky>
      <div class="head-banner">
        <Banner :active="false" />
        <div class="title-wraper">
          <div class="title">尊敬的参会嘉宾：</div>
          <div class="text">您好！欢迎您莅临2024互联网岳麓峰会，峰会将于2024年9月9日在长沙举行。</div>
        </div>
      </div>
      <!-- <div class="basic-wraper">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">会议地点</div>
          </van-divider>
        </div>
        <p>梅溪湖金茂精选酒店（具体安排详见2023互联网岳麓峰会日程）</p>
      </div> -->

      <!-- <div class="basic-wraper">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">会议地点</div>
          </van-divider>
        </div>
        <div class="normal"> <van-icon name="hotel-o" /> 【梅溪湖金茂精选酒店】</div>
        <div class="normal">电话：0731-88698888</div>
        <div class="normal">地址：湖南省长沙市岳麓区梅溪湖环湖路1177号</div>
        <div class="tips">温馨提示：</div>
        <div class="tips">分会场地点详情日程。另因疫情防控需要，入住长沙酒店均需出示通信行程绿码方可办理入住手续。</div>
      </div> -->

      <div class="basic-wraper traffic">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">会场导航：</div>
          </van-divider>
        </div>
        <div class="map-container" @click="handleGoMap">
          <img src="https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071233237051063.png" alt="" />
          <div class="text-wraper">
            <!-- <span>开幕式 | 岳麓论坛地址：梅溪湖金茂精选酒店</span> -->
            <span @click.stop="handleGoMap">
              地图导航
              <img :src="require('@/assets/images/reception/guide.png')" alt="" />
            </span>
          </div>
        </div>
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">公共交通：</div>
          </van-divider>
        </div>
        <!-- <div class="fir-title">公共交通</div> -->
        <div class="sec-title">地铁站：</div>
        <p>2号线-梅溪湖文化艺术中心站</p>
        <div class="sec-title">公交站：</div>
        <p>株树咀站：W158路</p>
        <p>金茂广场（株树咀）站（227米）：418路</p>
        <p>文化艺术中心地铁站（333米）：418路、W158路、社区巴士11号线、W107路</p>
        <p>梅溪湖东地铁站（565米）：228路、68路、270路、W115路、322路、社区巴士5号线、211路、205路、371路、312路、219路、356路、319路、W158路</p>
        <p>梅溪湖大桥北（597米）：356路、418路、367路、319路、312路</p>

        <!-- <div class="fir-title">本地嘉宾交通指引：</div>
        <div class="sec-title">梅溪湖金茂精选酒店附近的公交站：</div>
        <p>株树咀、文化艺术中心地铁站、金茂府、市一中岳麓中学、梅溪湖东地铁站、梅溪湖路连湖二路口、长围子、梅溪湖大桥北、梅溪湖路近湖二路口、映日路临水路口。</p>
        <div class="sec-title">梅溪湖金茂精选酒店附近的公交车：</div>
        <p>205路、207路、211路、219路、228路、269路、270路、306路、312路、319路、356路、367路、371路、418路、W107路、W115路、W116路、W201路、w115路、w158路、社区巴士5号线、社区巴士11号线、社区巴士18号线。</p>
        <div class="tips">温馨提示：</div>
        <div class="tips">梅溪湖金茂精选酒店会议期间并未实行交通管制。因酒店停车位数量有限，建议您尽量选择公共交通出行</div> -->
      </div>

      <!-- <div class="basic-wraper">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">防疫须知：</div>
          </van-divider>
        </div>
        <div class="normal">为保障您与他人的身体健康，请您熟知以下防疫要求：</div>
        <div class="narmal">1、如有以下情况的，不得参加本次会议：</div>
        <p class="normal">①大会前14 天内有国(境)外或港台旅居史的;</p>
        <p class="normal">
          ②大会前10 天内有海南、西藏、新疆(含兵团)、青海、陕西、重庆全域等疫情严重地旅居史的;近7天内有外溢风险较大的地区(江西鹰潭，河南三门峡、信阳，广东深圳南山区、宝安区、盐田区、佛山禅城区、广州越秀区，甘肃庆阳，湖北襄阳，福建泉州晋江，四川阿坝州、南充，黑龙江齐齐哈尔、大庆，安徽安庆，云南昆明盘龙区，辽宁大连沙河口区，内蒙古阿拉善、二连浩特，浙江宁波鄞州区，吉林珲春，广西防城港)旅居史的; 近7天内有高、中风险地区所在县(县级市、区、自治县、旗、自治旗等)旅居史的;
        </p>
        <p class="normal">③有发热、干咳、乏力、咽痛、鼻塞、流涕、结膜炎、肌肉酸痛、味嗅觉减退或丧失、腹泻等新冠肺炎相关可疑症状且不能排除传染病;</p>
        <p class="normal">④湖南居民健康码为红码或黄码的;</p>
        <p class="normal">⑤正处隔离治疗、隔离医学观察、居家健康监测的;或与新冠病例有时空交集的;</p>
        <p class="normal">
          ⑥报到时不能提供48小时内核酸检测阴性证明的(省外常态化防控区旅居史人员无抵长后“三天两检”核酸阴性证明);
        </p>
        <p class="normal">⑦高风险岗位从业人员脱离岗位后，未完成7天集中或居家隔离者;</p>
        <p class="normal">⑧工作人员不能提供大会前3天2次核酸阴性证明的(工作人员会议期间须每日1 次核酸检测);</p>
        <p class="normal">⑨经防疫部门评估，其他情况不适合参会的。</p>
        <p class="normal">2、核酸检测</p>
        <p class="normal sub-title">
          <span class="weight">会前：</span>所有参会人员及工作人员报到时须做一次核酸检测(当天在“三站一场”已开展了落地核酸检测的不需重复做)。
        </p>
        <p class="normal sub-title">
          <span class="weight">会期：</span>所有参会嘉宾持48小时内核酸检测阴性证明入长或返长，报到时须做一次核酸检测(当天在“三站一场”已开展了落地核酸检测的不需重复做)，省外来（返）长人员在会议入场时需提供入（返）湘后“三天两检”核酸阴性证明。
        </p>
        <p class="normal sub-title">组委会也将在峰会定点酒店及会场外每天设置免费核酸采样点为嘉宾提供服务，会议期间必须按照组委会安排每日开展1次核酸，持24小时核酸检测阴性证明方可进入会场。</p>
        <div class="tips">注:核酸检测前48小时不能接种新冠疫苗。</div>
      </div> -->

      <!-- <div class="basic-wraper">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">会务接待</div>
          </van-divider>
        </div>
        <p class="normal">如果您在参会过程中遇到会务接待问题，欢迎随时与我们联系！</p>
        <p class="tips normal">会务咨询：（待确认）</p>
        <p class="tips normal">接待咨询：（待确认）</p>
      </div> -->

      <!-- <div class="basic-wraper">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">注册报名</div>
          </van-divider>
        </div>
        <p>参会嘉宾进入岳麓峰会官方微信公众号提交报名资料，报名审核通过后凭有效电子票进入指定会场。</p>
        <div class="normal">报名通道开放时间：2023年8月20日一8月29日</div>
      </div> -->

      <div class="basic-wraper official">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">岳麓峰会官方微信公众号</div>
          </van-divider>
        </div>
        <p>
          请提前关注岳麓峰会官方微信公众号，大会组委会重要通知、日程安排、动态等信息将由官方微信公众号推送和提供服务。
        </p>
        <div class="qr-code">
          <img src="https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071233547774107.png" alt="" />
          扫一扫获取更多会议信息
        </div>
      </div>

      <!-- <div class="basic-wraper">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">网络服务</div>
          </van-divider>
        </div>
        <div>会议期间组委会将在酒店及会场提供免费wifi。</div>
      </div> -->

      <div class="basic-wraper">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">温馨提示</div>
          </van-divider>
        </div>
        <p class="tips">
          为了保证您能顺利参会，请您熟知以下内容：
        </p>
        <p class="tips">
          1、报名信息：本次峰会均为实名制，报名时请如实填写本人真实有效信息，会议签到过程中如发现身份信息不符将无法参会。
        </p>
        <p class="tips">
          2、报名审核： 峰会主办方将对报名人员进行审核，审核通过后方可参会，审核结果将于9月8日前陆续通知，敬请关注岳麓峰会微信公众号。
        </p>
        <p class="tips">
          3、审核结果查询：您可在岳麓峰会公众号，点击峰会报名查询报名审核结果。温馨提醒：只能参加审核通过的场次，未审核通过的场次将无法参加！
        </p>
        <p class="tips">
          4、请参会人员持报名审核通过的二维码参会，二维码不得转借、复制、仿造，否则所产生的后果自行承担。
        </p>
        <p class="tips">
          5、为了给您带来更好的参会体验，建议您着商务便装出席会议，参会期间请记得将您的手机等通讯设备调至静音或振动状态。
        </p>
        <p class="tips">
          6、会场为无烟会场，会场内请勿吸烟。
        </p>
        <p class="tips">
          7、会议期间您的贵重物品请随身携带，注意保管。
        </p>

        <p class="normal">期待与您在星城长沙相见！</p>
        <br>
        <div class="box1">
          <div>
            <span class="">峰会新十年  湘商新征程专场：</span><a href="tel:15580085756" class="color-text"> <span>张廷翔</span> 15580085756</a>
          </div>
          <div><span >文化智核  科技新篇专场： </span><a href="tel:18108402577" class="color-text"> <span>熊洛</span> 18108402577</a></div>
          <div> <span >投资新趋势 产业新格局专场： </span><a href="tel:18806644170" class="color-text"> <span>袁思嘉</span> 18806644170</a></div>
          <div><span >产业互联网 智汇长沙城专场： </span><a href="tel:18974857257" class="color-text"> <span>林珊</span> 18974857257</a></div>
          <div><span >AI驱动 数实融合专场： </span><a href="tel:18874793633" class="color-text"> <span>黄翔</span> 18874793633</a></div>
          <div><span >系统支持： </span><a href="tel:13873158203" class="color-text"> <span>张工</span> 13873158203</a></div>
        </div>
        <p class="normal">咨询时间：周一至周五9:00-17:00</p>
        <br>
        <!-- <div class="tips">
          <p class="normal">报名咨询热线：0731-88992699</p>
          <p class="normal">咨询时间：周一至周五9:00-17:00</p>
        </div> -->
      </div>
    </template>

    <Map v-else />

    <float-menu />
  </div>
</template>

<script>
import Banner from "@/components/banner/index";
import floatMenu from "@/components/floatMenu/index";
import headBar from "@/components/headBar/headBar";
import Map from "./components/map/index";
import wx from '@/utils/wx'
export default {
  name: "reception",
  components: { floatMenu, Banner, Map, headBar },
  data() {
    return {
      pageShow: true
    };
  },
  methods: {
    handleGoMap() {
      // this.$router.push("/map/" + this.$store.state.miceInfo.miceLink);
      wx.openLocation( 28.196569,112.906392,"梅溪湖文化艺术中心","梅溪湖文化艺术中心")
    },
    onClickLeft() {
      this.$router.push("/index/" + this.$store.state.miceInfo.miceLink);
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.van-divider--content-left::before {
  max-width: 0;
  margin: 0;
}
.box1{
  div{
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }
  a {
    width: 130px;
    text-align: left;
    span {
      width: 40Px;
      display: inline-block;
    }
  }
}
.reception-container {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgb(242, 248, 252);
  .basic-wraper {
    padding: 15px;
    background-color: #fff;
    margin-bottom: 10px;
    color: #666;
    font-size: 12px;
    p {
      margin: 5px 0 15px 0;
      line-height: 1.9;
    }
    p.normal {
      margin: 0;
    }
    .tips-title {
      font-weight: bolder;
    }
    .tips {
      color: rgb(226, 147, 44);
    }
    .normal {
      line-height: 2;
    }
    .basic-title {
      margin-bottom: 10px;
      .van-divider {
        margin: 0;
        color: #333;
        font-size: 14px;
        font-weight: bold;
      }
      .title-text {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          height: 20px;
          width: 20px;
          margin-right: 5px;
          background-image: url("~@/assets/images/reception/title-icon.png");
          background-size: 100% 100%;
          background-position: center;
        }
      }
    }
  }
  .head-banner {
    position: relative;
    margin-bottom: 10px;
    .title-wraper {
      // position: absolute;
      bottom: -125px;
      background-color: #fff;
      padding: 20px;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      font-size: 13px;
      color: #333;
      .title {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
      }
      .text {
        text-indent: 28px;
        line-height: 1.8;
      }
    }
  }
  .traffic {
    .map-container {
      width: 100%;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
      background: #fff;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      img {
        height: 110px;
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
      .text-wraper {
        width: 100%;
        height: 40px;
        display: flex;
        // flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        span:nth-of-type(1) {
          display: flex;
          padding: 5px 10px;
          background: #06c671;
          color: #fff;
          font-size: 12px;
          border-radius: 4px;
          align-items: center;
          img {
            margin-left: 5px;
            // height: 13px;
            height: auto;
            width: 13px;
          }
        }
      }
    }
    .fir-title {
      color: #3c97ed;
      font-size: 14px;
      margin: 15px 0;
    }
    .sec-title {
      font-weight: bold;
      font-size: 13px;
      color: #333;
    }
  }
  .official {
    .qr-code {
      display: flex;
      // margin-top: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f2f8fc;
      padding: 15px 0;
      img {
        height: 100px;
        width: 100px;
        margin-bottom: 10px;
      }
    }
  }
}
.sub-title {
  text-indent: 2em;
  .weight {
    font-weight: bolder;
  }
}
</style>
