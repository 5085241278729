<template>
  <div class="map-container">
    <div id="container"></div>
    <div id="panel"></div>

    <div class="place-wrapper">
      <div class="btn_item btn_hotel" @click="handleHotel">
        酒店
        <img :src="require('@/assets/images/map/hotel.png')" alt="">
        <!-- <img v-if="active_btn===1" :src="require('@/assets/images/map/icon_1_active.png')" alt="">
        <img v-else :src="require('@/assets/images/map/icon_1.png')" alt=""> -->
      </div>
      <div class="btn_item btn_meeting" @click="handleMeetting">
        会场
        <img :src="require('@/assets/images/map/meetting.png')" alt="">
        <!-- <img v-if="active_btn===2" :src="require('@/assets/images/map/icon_2_active.png')" alt="">
        <img v-else :src="require('@/assets/images/map/icon_2.png')" alt=""> -->
      </div>
      <!-- <div class="btn_item btn_ylfh" @click="handleYlfh">
        <img v-if="active_btn===3" :src="require('@/assets/images/map/icon_3_active.png')" alt="">
        <img v-else :src="require('@/assets/images/map/icon_3.png')" alt="">
      </div> -->
    </div>

    <transition>
      <div v-if="popupShow" class="mask" @click="handleCancal"></div>
    </transition>

    <transition name="slide">
      <div v-show="popupShow" class="place-list">
        <template v-if="!mapListShow">
          <div v-for="(item,index) in placeList" :key="index" class="place-item" @click="handlePoint(item)">
            {{item.label}}
          </div>   
        </template>
        <template v-else>
          <div v-for="(item,index) in placeList" :key="index" class="place-item" @click="handleOpenMap(item)">
            {{item.label}}
          </div>   
        </template>
      </div>
    </transition>

    <transition name="slide">
      <div v-show="popupShow" class="btn_cancel" @click="handleCancal">
        取消
      </div>
    </transition>

    <div v-if="!routeShow" @click="handleCarRoute"  class="car-wrapper">
      <img :src="require('@/assets/images/map/car.png')" alt="">
      去这里
    </div>
    <div v-else @click="handleMap"  class="car-wrapper">
      <p>打开</p>
      <p>地图</p>
    </div>
    <!-- <div class="info">
      <h4 id='status'></h4><hr>
      <p id='result'></p><hr>
      <p >由于众多浏览器已不再支持非安全域的定位请求，为保位成功率和精度，请升级您的站点到HTTPS。</p>
    </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data () {
    return {
      placeList: [],
      popupShow: false,
      active_btn: '',
      hotelList: [
        { label: '一缦酒店', lng: '112.878441', lat: '28.227467' },
        { label: '凯旋龙酒店', lng: '112.916572', lat: '28.210212' },
        { label: '86悦巢云缦酒店', lng: '112.942754', lat: '28.168719' }
      ],
      mettingList: [
        { label: '会场1',  lng: '112.945311', lat: '28.178169'  },
        { label: '会场2',  lng: '113.012768', lat: '28.096892'  }
      ],
      ylfh: {
        name: '梅溪湖金茂精选酒店',
        lng: '112.904407',
        lat: '28.195549'
      },
      user: {
        lng: '',
        lat: ''
      },
      startPlace: {},
      endPlace: {},
      routeShow: false, // 是否出现路线
      mapList: [
        { value: 1, label: '高德地图' },
        { value: 2, label: '腾讯地图' },
        { value: 3, label: '百度地图' }
      ],
      mapListShow: false
    }
  },
  computed: {
    id() {
      if (this.routeShow) {
        return 'goApp'
      } else {
        return ''
      }
    }
  },
  mounted() {
    this.getLocation()
  },
  methods: {
    handleHotel() {
      this.active_btn = 1
      this.placeList = [...this.hotelList]
      this.popupShow = true
    },

    handleMeetting() {
      this.active_btn = 2
      this.placeList = [...this.mettingList]
      this.popupShow = true
    },

    handleYlfh() {
      this.active_btn = 3
      this.baseMapDraw(this.ylfh, 1)
    },
    // 点击打开地图
    handleMap() {
      this.mapListShow = true
      this.placeList = [...this.mapList]
      this.popupShow = true
    },

    handleCancal() {
      this.popupShow = false
    },

    // 选择酒店 会场
    handlePoint(data) {
      this.endPlace = data
      this.baseMapDraw(data)
      this.popupShow = false
      var button = document.getElementById('goApp')
      if (button) {
        button.onclick = null
        this.routeShow = false
      }
      this.routeShow = false
    },

    handleOpenMap(data) {
      const gaodeMap = `//uri.amap.com/navigation?from=${this.startPlace.lng},${this.startPlace.lat},startpoint&to=${this.endPlace.lng},${this.endPlace.lat},endpoint&mode=car`
      const txMap = `https://apis.map.qq.com/uri/v1/routeplan?type=drive&fromcoord=${this.startPlace.lat},${this.startPlace.lng}&to=${this.endPlace.label}&tocoord=${this.endPlace.lat},${this.endPlace.lng}&policy=1&referer=ylfh`
      const baiduMap = `http://api.map.baidu.com/direction?origin=latlng:${this.startPlace.lat},${this.startPlace.lng}|name&destination=latlng:${this.endPlace.lat},${this.endPlace.lng}|name:${this.endPlace.label}&mode=driving&region=湖南&output=html&src=webapp.baidu.openAPIdemo`
      
      let href = ''
      if (data.value===1) {
        href = gaodeMap
      } else if (data.value===2) {
        href = txMap
      } else {
        href = baiduMap
      }
      window.location.href = href
    },

    handleCarRoute() {
      if (!this.startPlace.lng) {
        Toast('没有获取到当前位置！')
        return
      }
      if (!this.endPlace.lng) {
        Toast('没有获取到终点位置！')
        return
      }
      this.getCarRoute(this.startPlace, this.endPlace)
    },

    getLocation() {
      var map = new AMap.Map('container', {
        resizeEnable: true
      });
      const vm = this
      AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,//是否使用高精度定位，默认:true
          timeout: 1000,          //超过10秒后停止定位，默认：5s
          buttonPosition:'RB',    //定位按钮的停靠位置
          buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点

        });
        map.addControl(geolocation);
        geolocation.getCurrentPosition(function(status,result){
          console.log(status, result)
            if(status=='complete'){
              vm.startPlace.lng = result.position.lng
              vm.startPlace.lat = result.position.lat
              onComplete(result)
            }else{
              onError(result)
            }
        });
      });
      //解析定位结果
      function onComplete(data) {
        console.log('定位成功')
        // document.getElementById('status').innerHTML='定位成功'
        var str = [];
        str.push('定位结果：' + data.position);
        str.push('定位类别：' + data.location_type);
        if(data.accuracy){
            str.push('精度：' + data.accuracy + ' 米');
        }//如为IP精确定位结果则没有精度信息
        str.push('是否经过偏移：' + (data.isConverted ? '是' : '否'));
        // document.getElementById('result').innerHTML = str.join('<br>');
      }
      //解析定位错误信息
      function onError(data) {
        console.log('定位失败', data.message)
        // document.getElementById('status').innerHTML='定位失败'
        // document.getElementById('result').innerHTML = '失败原因排查信息:'+data.message;
      }
    },

    getCarRoute(startPlace, endPlace) {
      const vm = this
      var map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 13 //地图显示的缩放级别
      })
      // 构造路线导航类
      var driving = new AMap.Driving({
        map: map
      })
      // 根据起终点经纬度规划驾车导航路线
      driving.search(new AMap.LngLat(startPlace['lng'], startPlace['lat']), new AMap.LngLat(endPlace['lng'], endPlace['lat']), function(status, result) {
        // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
        if (status === 'complete') {
          vm.routeShow = true
          // log.success('绘制驾车路线完成')
          // setTimeout(() => {
          //   var button = document.getElementById('goApp');
          //   console.log(button)
          //   button.onclick = function(){
          //     driving.searchOnAMAP({
          //         origin:result.origin,
          //         destination:result.destination
          //     });
          //   }
          // }, 10)
        } else {
          log.error('获取驾车数据失败：' + result)
        }
      })
    },

    baseMapDraw(data, type) {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        center: [data['lng'], data['lat']],
        zoom: 13 // 地图显示的缩放级别
      })

      var startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(23, 38),
        // 图标的取图地址
        image: require('@/assets/images/map/marker.png'),
        // 图标所用图片大小
        imageSize: new AMap.Size(23, 38),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(0, 0)
      });

      // 构造点标记
      var marker = new AMap.Marker({
        icon: type ?  startIcon : "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png",
        position: [data['lng'], data['lat']]
      })
      map.add(marker)

    }

  }
}
</script>

<style lang="less" scoped>
  .map-container {
    width: 100%;
    height: 100%;
    position: relative;
    #container {
      width: 100%;
      height: 100%;
    }

    .place-wrapper {
      position: absolute;
      right: 17px;
      top: 16px;
      z-index: 9;
      width: 120px;
      font-size: 14px;
      color: #fff;
      .btn_item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 54px;
        border-radius: 27px;
        margin-bottom: 14px;
        font-size: 20px;
        background-image: linear-gradient(#5737bf  , #2d3ca9);
        img {
          margin-left: 6px;
          width: 40px
        }
      }
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      z-index: 9999;
    }

    .place-list {
      position: absolute;
      left: 15px;
      bottom: 90px;
      width: 345px;
      z-index: 9999;
      background: #fff;
      font-size: 16px;
      text-align: center;
      border-radius: 6px;
      max-height: 450px;
      overflow-y: auto;
      color: #0092fc;
      font-size: 15px;
      .place-item {
        height: 50px;
        line-height: 50px;
        border-bottom:1px solid #e5e6f8;
        text-overflow: ellipsis;
        white-space:nowrap; 
        overflow: hidden; 
        &:nth-last-child(1) {
          border: none;
        }
      }
    }

    .btn_cancel {
      position: absolute;
      left: 15px;
      bottom: 30px;
      width: 345px;
      height: 50px;
      z-index: 9999;
      background: #fff;
      line-height: 50px;
      font-size: 16px;
      text-align: center;
      border-radius: 6px;
      color: #0092fc;
      font-size: 15px;
      font-weight: bold;
    }

    .car-wrapper {
      position: absolute;
      right: 20px;
      bottom: 100px;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 999;
      background-image: linear-gradient(to bottom, #2e3ca7, #5d40c0);
      font-size: 14px;
      color: #fff;
      img {
        width: 30px;
      }

      p {
        margin: 0;
      }
    }

  }

  #callApp{
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    width: 11rem
  } 


  .v-enter, .v-leave-to{
    opacity: 0;
  }
  .v-enter-active, .v-leave-to{
    transition: .3s;
  }

  .slide-enter, .slide-leave-to{
    transform: translateY(100%);
  }
  .slide-enter-active, .slide-leave-to{
    transition: .3s;
  }
</style>